import React, { useState, useEffect, useRef } from 'react';
import "./Bio.css";
import { useSpring, animated } from '@react-spring/web';
import { motion } from 'framer-motion';
import photo from "../images/Kostas_ProfilePic.jpg";
import SkillsComponent from '../components/SkillsComponent';


function Bio() {
  const paperTextRef = useRef(null); // Create a ref to access the element
  const [propsSlideRightOp] = useSpring(
    () => ({
      from: { opacity: 0, y: '-100%' },
      to: { opacity: 1 , y: '0%' },
      delay: 500
    }),
    []
  );

  // eslint-disable-next-line no-unused-vars
  const [isVisible, setIsVisible] = useState(false);

 
  return (
    <div> 
   
      {/* <motion.img className="paper-overlay" src={paper_edges} alt=""  initial={{opacity:0 , transition: { duration: 0.1}}} animate={{opacity:0.5, transition: { duration: 0.5, delay:0 , ease: "easeInOut"}}} exit={{opacity:0, transition: { duration:0.2, ease: "easeInOut" }}}/> */}
      <motion.div exit={{opacity:0, transition: { duration:0.2, ease: "easeInOut" }}} className={`mt-3 container-fluid my-body ${isVisible ? 'visible' : ''}`}>
        <motion.div exit={{opacity:0, transition: { duration:0.2, ease: "easeInOut" }}}>
          <animated.div style={propsSlideRightOp}>
            <div className='container-fluid top-bar'>
              <div className='row align-items-center'>
                <div className='h1 fw-light text-decoration paper-title w-auto m-auto'>Biography </div>
              </div>
            </div>
          </animated.div>
        </motion.div>

        <br/>

        <motion.div key="body" initial={{opacity:0, transition: { duration: 0.1}}} animate={{opacity:100, transition: { duration: 0.3, delay:0.2 , ease: "easeInOut"}}} exit={{opacity:0, x:'-100%', transition: { duration: 0.2, ease: "easeInOut" }}} className="my-div">
        
          <div className="container biography-container fw text-decoration scrollable">
            <div className="row">
              
            <div className="col-md-4">
                      <motion.div className="" initial={{opacity:0,x:"-0vw",rotate:40, rotateX:"-91deg",rotateZ:"-5deg", scale:2, transition: { duration: 0.1}}} animate={{opacity:1,x:"0",rotate:0, rotateX:0,rotateZ:"0deg", scale:1, transition: { duration: 0.8, delay:0.5 , ease: "backOut"}}} exit={{opacity:0, x:'-100%', transition: { duration: 0.2, ease: "backInOut" }}}>
                        <div className='custom-hover2 profile-picture'>
                          <img src={photo} alt="Profile" className="my-pic" />
                        </div> 
                      </motion.div>
                    </div>
              <div className="col-md-8 custom-hover">

                <motion.div className="text-decoration h5 fw-light paper-text" id='paperText' ref={paperTextRef} initial={{opacity:0,x:"10vw",y:"40vh",rotate:"20deg",rotateX:"-90deg", scale : 0.5 , transition: { duration: 0.1}}} animate={{opacity:1,x:0,y:0,rotate:"0deg", rotateX:"0deg", scale : 1 , transition: { duration: 1.2, delay:0.5 , ease: "backInOut"}}} exit={{opacity:0, x:'-100%', transition: { duration: 0.2, ease: "backOut" }}} style={{height : "auto"}}>
                  <motion.div className="personal-info text-decoration h5 fw-light" initial={{opacity:0, transition: { duration: 0.1}}} animate={{opacity:100, transition: { duration: 0.2, delay:0 , ease: "ease"}}} exit={{opacity:0, x:'-100%', transition: { duration: 0.2, ease: "easeInOut" }}}>
                      <div className='info-text'>
                        <div className='h4 fw-light'>In a  nutshell...</div><br/><br/>
                      With a foundation in full-stack development and a diverse portfolio across freelance and corporate environments, I specialize in crafting tailored digital solutions driven by a passion for problem-solving and a focus on clean, maintainable code.<br/><br/>
                      
                      Currently, I contribute to digital business applications at Titan Cement Company Greece, where I leverage Microsoft’s business platforms to streamline and enhance operational workflows. <br/><br/>
                      </div>
                      
                  </motion.div>
                </motion.div>

              </div>  
              <hr/>
              <motion.div initial={{opacity:0,y:"30vh",rotateZ:"0deg", scale:1, transition: { duration: 0.1}}} animate={{opacity:1, y:0,rotateZ:"0deg", scale:1, transition: { duration:0.7, delay:0.7 , ease: "backOut"}}} exit={{opacity:0, x:'-100%', transition: { duration: 0.2, ease: "backOut" }}} className="row skill-container">
                    <br/>
                    
                    {/* <div className="col-md-8"> */}
                    <SkillsComponent className=''/>
                    {/* </div> */}
              </motion.div>
              <br/><br/>
              
            </div>
          </div>
        </motion.div>
      <br/><br/><br/><br/><br/><br/>
      </motion.div>
      
    </div>
  );
}

export default Bio;