import React, { useState, useEffect } from 'react';
import './SkillsComponent.css'; // Ensure this points to your CSS file


const Skills = () => {
  const skills = [
    'React.js',
    'JavaScript',
    'HTML5 / CSS3',
    'Node.js',
    'C++',
    'C',
    'Python',
    'PHP',
    'HTML5',
    'CSS / CSS3',
  ];

  const [activeSkill, setActiveSkill] = useState(0);
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    if (!isHovering) {
      const interval = setInterval(() => {
        setActiveSkill((prevActiveSkill) => (prevActiveSkill + 1) % skills.length);
      }, 1500); // Change the skill every 2 seconds

      return () => clearInterval(interval);
    }
  }, [isHovering, skills.length]);

  const handleMouseEnter = (index) => {
    setIsHovering(true);
    setActiveSkill(index);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <div className="skills">
      <div className="h3 fw-light">Skills</div>
      {/* <ul className="h5 fw-light list-unstyled"> */}
      <div className="table skill-table">
        <div>
            <div>
              <div className={`skill-option skill-title`}>Coding languages</div>
            </div>
        </div>
        <div>
          <div>
          {skills.map((skill, index) => (
       
     
            <div
              key={index}
              className={`skill-option ${index === activeSkill ? 'active' : ''}`}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              {/* {skill} {index === activeSkill ?  <Typewriter text=" || Information" /> : ' '} */}
              {skill}
            </div>

          ))}
          </div>

        </div>
      </div>
      {/* </ul> */}
    </div>
  );
};

export default Skills;
