import React, { useRef, useEffect, useState } from 'react';
import "./HoopGame.css";

import SuccessMessage from './SuccessMessage';
import floorHitSound from './sounds/floor-hit-1.wav';
import hoopHitSound1 from './sounds/hoop-hit-1.wav';
import hoopHitSound2 from './sounds/hoop-hit-2.wav';
import netSwooshSound from './sounds/net-swoosh.wav';
import crowdSound1 from './sounds/crowd-1.wav';
import crowdSound2 from './sounds/crowd-2.wav';
import soundtrackSound from './sounds/soundtrack.mp3';
import basketball from './images/basketball.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeUp, faVolumeMute, faPlay, faPause, faExpand,faCompress, faHand } from '@fortawesome/free-solid-svg-icons';

const HoopGame = () => {
  // canvas refs
  const canvasRef = useRef(null);
  const canvas = canvasRef.current;
  const [canvasSize, setCanvasSize] = useState({ width: 900, height: 500 }); // Initial canvas size
  const [scale, setScale] = useState(1); // Initial scale factor


  //==========================================================\
  //                   ---LEVELS---                           |
  //==========================================================/
  const freePlay = {level     : 0,
    text    : "Free Play",
    subtext : "Do anything and customize everything",
    startPositionX: 250,
    startPositionY: 200,
    touches   : null,
    goal    : null,
    ballsize: 80,
    hoop    :{width   : 130,
              height  : 10,
              x       : canvasSize.width - 130,
              y        : canvasSize.height / 3 }, 
    gravityX : 0, 
    gravityY : 0.1,
    friction : 0.995,
    preview  :{value    : false, 
              numPoints : 500, 
              timeStep  : 0.1 } }

  //==========================================================\
  //                      LEVEL 1                             |
  //==========================================================/
  const level1 =   {level     : 1,
      text      : "Level 1",
      subtext   : "Easy Shot",
      startPositionX: 250,
      startPositionY: 200,
      touches     : 3,
      goal      : 1,
      ballsize  : 70,
      hoop      :{width     : 120,
                  height    : 10,
                  x         : canvasSize.width - 120,
                  y         : canvasSize.height  / 3 }, 
      gravityX  : 0, 
      gravityY  : 0.1,
      friction  : 0.995,
      preview   :{value     : false, 
                  numPoints : 1000, 
                  timeStep  : 0.1 } }


  //==========================================================\
  //                      LEVEL 2                             |
  //==========================================================/
  const level2 =   {level     : 2,
      text      : "Level 2",
      subtext   : "Faraway Shot",
      startPositionX: 200,
      startPositionY: 300,
      touches     : 3,
      goal      : 1,
      ballsize  : 40,
      hoop      :{width   : 70,
                  height  : 10, 
                  x       : canvasSize.width - 70,
                  y       : canvasSize.height / 2 }, 
      gravityX  : 0, 
      gravityY  : 0.1,
      friction  : 0.995,
      preview   :{value     : false, 
                  numPoints : 1200, 
                  timeStep  : 0.1 } }


  //==========================================================\
  //                      LEVEL 3                             |
  //==========================================================/
  const level3 =   {level     : 3,
      text      : "Level 3",
      subtext   : "Less Aim Assist",
      startPositionX: 200,
      startPositionY: 250,
      touches     : 3,
      goal      : 1,
      ballsize  : 40,
      hoop      :{width   : 70,
                  height  : 10,
                  x       : canvasSize.width - 70,
                  y       : canvasSize.height / 3 }, 
      gravityX  : 0, 
      gravityY  : 0.1,
      friction  : 0.995,
      preview   :{value     : false, 
                  numPoints : 500, 
                  timeStep  : 0.1 } }


  //==========================================================\
  //                      LEVEL 4                             |
  //==========================================================/
  const level4 =   {level     : 4,
      text      : "Level 4",
      subtext   : "Single Touch",
      startPositionX: 220,
      startPositionY: 240,
      touches     : 1,
      goal      : 1,
      ballsize  : 40,
      hoop      :{width   : 70,
                  height  : 10,
                  x       : canvasSize.width - 70,
                  y       : canvasSize.height / 3 }, 
      gravityX  : 0, 
      gravityY  : 0.1,
      friction  : 0.995,
      preview   :{value     : false, 
                  numPoints : 500, 
                  timeStep  : 0.1 } } 
                
                  
  //==========================================================\
  //                      LEVEL 5                             |
  //==========================================================/
  const level5 =   {level     : 5,
      text      : "Level 5",
      subtext   : "Bounce it",
      startPositionX: 240,
      startPositionY: 300,
      touches     : 2,
      goal      : 1,
      ballsize  : 40,
      hoop      :{width   : 70,
                  height  : 10,
                  x       : canvasSize.width - 70,
                  y       : canvasSize.height / 3 }, 
      gravityX  : 0, 
      gravityY  : 0.1,
      friction  : 0.995,
      preview   :{value     : false, 
                  numPoints : 500, 
                  timeStep  : 0.1 } }     


  const Level5Square = {
    shape : 'rectangle',
    x: 300, // X coordinate of the top-left corner
    y: -500, // Y coordinate of the top-left corner
    width: 80, // Width of the rectangle
    height: 750, // Height of the rectangle
  };


  //==========================================================\
  //                      LEVEL 6                             |
  //==========================================================/
  const level6 =   {level     : 6,
    text      : "Level 6",
    subtext   : "Angles Matter",
    startPositionX: 440,
    startPositionY: 400,
    touches     : 1,
    goal      : 1,
    ballsize  : 40,
    hoop      :{width   : 70,
                height  : 10,
                x       : canvasSize.width - 70,
                y       : canvasSize.height / 3 }, 
    gravityX  : 0, 
    gravityY  : 0.1,
    friction  : 0.995,
    preview   :{value     : false, 
                numPoints : 500, 
                timeStep  : 0.1 } }     


  const Level6Square1 = {
  shape : 'rectangle',
  x: 250, // X coordinate of the top-left corner
  y: -500, // Y coordinate of the top-left corner
  width: 80, // Width of the rectangle
  height: 750, // Height of the rectangle
  };

  const Level6Square2 = {
    shape : 'rectangle',
    x: 550, // X coordinate of the top-left corner
    y: 200, // Y coordinate of the top-left corner
    width: 80, // Width of the rectangle
    height: 750, // Height of the rectangle
  };
  


  //==========================================================\
  //                      LEVEL 7                             |
  //==========================================================/
  const level7 =   {level     : 7,
    text      : "Level 7",
    subtext   : "Drill the hole",
    startPositionX: 140,
    startPositionY: 400,
    touches     : 1,
    goal      : 1,
    ballsize  : 30,
    hoop      :{width   : 200,
                height  : 10,
                x       : canvasSize.width - 200,
                y       : canvasSize.height / 2 }, 
    gravityX  : 0, 
    gravityY  : 0.1,
    friction  : 0.995,
    preview   :{value     : false, 
                numPoints : 300, 
                timeStep  : 0.1 } }     


  // Define the coordinates of the triangle vertices
  const Level7Square1 = {
    shape : 'rectangle',
    x: 650, // X coordinate of the top-left corner
    y: -700, // Y coordinate of the top-left corner
    width: 50, // Width of the rectangle
    height: 750, // Height of the rectangle
  };

  const Level7Square2 = {
    shape : 'rectangle',
    x: 650, // X coordinate of the top-left corner
    y: 130, // Y coordinate of the top-left corner
    width: 50, // Width of the rectangle
    height: 750, // Height of the rectangle
  };
  


  //==========================================================\
  //                      LEVEL 8                             |
  //==========================================================/
  const level8 =   {level     : 8,
    text      : "Level 8",
    subtext   : "Mildly annoying",
    startPositionX: 100,
    startPositionY: 300,
    touches     : 6,
    goal      : 1,
    ballsize  : 40,
    hoop      :{width   : 60,
                height  : 10,
                x       : canvasSize.width - 60,
                y       : canvasSize.height / 2 }, 
    gravityX  : 0, 
    gravityY  : 0.1,
    friction  : 0.995,
    preview   :{value     : false, 
                numPoints : 300, 
                timeStep  : 0.1 } }     


  // Define the coordinates of the triangle vertices
  const Level8Square1 = {
    shape : 'rectangle',
  x: 250, // X coordinate of the top-left corner
  y: -700, // Y coordinate of the top-left corner
  width: 50, // Width of the rectangle
  height: 750, // Height of the rectangle
  };

  const Level8Square2 = {
    shape : 'rectangle',
    x: 250, // X coordinate of the top-left corner
    y: 130, // Y coordinate of the top-left corner
    width: 50, // Width of the rectangle
    height: 750, // Height of the rectangle
  };

  const Level8Square3 = {
    shape : 'rectangle',
    x: 600, // X coordinate of the top-left corner
    y: -700, // Y coordinate of the top-left corner
    width: 70, // Width of the rectangle
    height: 750, // Height of the rectangle
    };
  
  const Level8Square4 = {
    shape : 'rectangle',
    x: 600, // X coordinate of the top-left corner
    y: 130, // Y coordinate of the top-left corner
    width: 70, // Width of the rectangle
    height: 750, // Height of the rectangle
  };
  

  //==========================================================\
  //                      LEVEL 9                             |
  //==========================================================/
  const level9 =   {level     : 9,
    text      : "Level 9",
    subtext   : "Time Your Touch",
    startPositionX: 100,
    startPositionY: 400,
    touches     : 3,
    goal      : 1,
    ballsize  : 40,
    hoop      :{width   : 60,
                height  : 10,
                x       : canvasSize.width - 60,
                y       : canvasSize.height / 2 }, 
    gravityX  : 0, 
    gravityY  : 0.1,
    friction  : 0.995,
    preview   :{value     : false, 
                numPoints : 300, 
                timeStep  : 0.1 } }     


 
  const Level9Square1 = {
  shape : 'rectangle',
  x: 150, // X coordinate of the top-left corner
  y: -700, // Y coordinate of the top-left corner
  width: 30, // Width of the rectangle
  height: 750, // Height of the rectangle
  };

  const Level9Square2 = {
    shape : 'rectangle',
    x: 150, // X coordinate of the top-left corner
    y: 130, // Y coordinate of the top-left corner
    width: 30, // Width of the rectangle
    height: 750, // Height of the rectangle
  };

  const Level9Square3 = {
    shape : 'rectangle',
    x: 600, // X coordinate of the top-left corner
    y: 300, // Y coordinate of the top-left corner
    width: 30, // Width of the rectangle
    height: 750, // Height of the rectangle
    };


  //==========================================================\
  //                      LEVEL 10                            |
  //==========================================================/
  const level10 =   {level     : 10,
    text      : "Level 10",
    subtext   : "Use the force... kind-of",
    startPositionX: 200,
    startPositionY: 150,
    touches     : 1,
    goal      : 1,
    ballsize  : 40,
    hoop      :{width   : 90,
                height  : 10,
                x       : canvasSize.width - 90,
                y       : canvasSize.height /2 }, 
    gravityX  : 0, 
    gravityY  : 0.1,
    friction  : 0.995,
    preview   :{value     : false, 
                numPoints : 300, 
                timeStep  : 0.1 } }     



  
  // Define the coordinates of the triangle vertices
  const Level10Sphere = {
    shape : 'sphere',
    x: 700, // X coordinate of the top-left corner
    y: 220, // Y coordinate of the top-left corner
    radius : 110,
    force : 0.3
  };

  const Level10Square1 = {
    shape : 'rectangle',
    x: 200, // X coordinate of the top-left corner
    y: -400, // Y coordinate of the top-left corner
    width : 400,
    height: 500
  };

  const Level10Square2 = {
    shape : 'rectangle',
    x: 200, // X coordinate of the top-left corner
    y: 350, // Y coordinate of the top-left corner
    width : 400,
    height: 200
  };
  
  const Level10Square3 = {
    shape : 'rectangle',
    x: 750, // X coordinate of the top-left corner
    y: 100, // Y coordinate of the top-left corner
    width :30,
    height: 500
  };


  //==========================================================\
  //                      LEVEL 11                            |
  //==========================================================/




  const levels = [freePlay, level1, level2, level3, level4, level5, level6, level7, level8, level9, level10];
  const savedLevels = localStorage.getItem('unlockedLevels');
  const unlockedLevelsRef = useRef();
  if (savedLevels) {
    unlockedLevelsRef.current = JSON.parse(savedLevels);
  }
  else{
    unlockedLevelsRef.current = [true,true,false,false,false,false, false, false, false, false];
  }

  const firstFalseIndex = unlockedLevelsRef.current.findIndex(item => item !== true);


  const [level,setLevel] = useState(firstFalseIndex!=-1?levels[firstFalseIndex-1]:levels[levels.length-1]);
  const levelRef = useRef(firstFalseIndex!=-1?levels[firstFalseIndex-1]:levels[levels.length-1]);





  //audio
  const hoop1 = new Audio(hoopHitSound1);
  hoop1.volume = 0.5;
  const hoop2 = new Audio(hoopHitSound2);
  hoop2.volume= 0.5;
  const [hoopSounds] = useState([
  hoop1,
  hoop2,
]);
//audio
const [crowdSounds] = useState([
  new Audio(crowdSound1),
  new Audio(crowdSound2),
]);
const floorSound = new Audio(floorHitSound);
floorSound.volume = 1;
const swooshSound = new Audio(netSwooshSound);
swooshSound.volume = 1;

const soundtrackRef = useRef(new Audio(soundtrackSound));
soundtrackRef.current.volume = 0.02;



// image of ball
const ballImage = new Image();
ballImage.src = basketball;
const ballsize = 80;
const hoopsize = 130;

var loopFactor = 0;

  


// all other
const velocityRef = useRef({ x: 0, y: 0 });
const friction = 0.995;
const gravityX = useRef(0.00);
const gravityY = useRef(0.1);
const isDraggingRef = useRef(false);
const dragStartRef = useRef({ x: 0, y: 0 });
const mouseCoordinatesRef = useRef({ x: 0, y: 0 });
const hitAnimationTimeRef = useRef(0);
const scoreAnimationTimeRef = useRef(0);
const bounceAnimationTimeRef = useRef(0);
const [sound, setSound] = useState(true);
const soundRef = useRef(true);
const [score, setScore] = useState(0);
const scoreRef = useRef(0);
const totalScoreRef = useRef(0);
const [started, setStarted] = useState(false);
const startedRef = useRef(false);
const [paused, setPaused] = useState(false);
const dragAndDropRef = useRef(false);
const [dragAndDrop, setDragAndDrop] = useState(false);
const pausedRef = useRef(false);
const [preview, setPreview] = useState(false);
const previewRef = useRef(false);
const [collidedWall, setCollidedWall] = useState(false);
const collidedWallRef = useRef({ time : 0, x: 0, y: 0 });
const [isFullscreen, setIsFullscreen] = useState(false);
const isFullscreenRef = useRef(false);
const positionRef = useRef({ x: levelRef.current.startPositionX, y: levelRef.current.startPositionY });
const currentTryRef = useRef(levelRef.current.touches);


var animationFrame;
var collided = false;
const [showSuccessMessage, setShowSuccessMessage] = useState(false);

const handleLevelChange = (index) => {
  // CHANGE LEVEL
  levelRef.current = levels[index];
  setLevel(levels[index]);


  // RESET SCORE, TOTAL AND TOUCHES
  setScore(0);
  scoreRef.current = 0;
  totalScoreRef.current = 0;
  currentTryRef.current = levelRef.current.touches;
  
  // SET POSITION
  positionRef.current.x = levelRef.current.startPositionX;
  positionRef.current.y = levelRef.current.startPositionY;

  // SET PAUSED STATE
  startedRef.current = false;
  setStarted(false);
}

const handleNextLevel = () => {
  // Logic to proceed to the next level
  setShowSuccessMessage(false);
  if(levelRef.current.level + 1 < levels.length){
    handleLevelChange(levelRef.current.level+1);
  }
};


// GRAVITY FUNCTIONS
function gravityUp(){
  gravityY.current-=0.05;
};
function gravityDown(){
  gravityY.current+=0.05;
};
function gravityRight(){
  gravityX.current+=0.05;
};
function gravityLeft(){
  gravityX.current-=0.05;
};



//Start, pause and sound functions

function handleStarted(){
  startedRef.current = !startedRef.current;
  setStarted((prev)=>!prev);
};

function handlePaused(){
  pausedRef.current = !pausedRef.current;
  setPaused((prev)=>!prev);
};

function handleSound(){
  soundRef.current = !soundRef.current;
  setSound((prev)=>!prev);
};

function handleDragAndDrop(){
  dragAndDropRef.current = !dragAndDropRef.current;
  setDragAndDrop((prev)=>!prev);
};



const toggleFullscreen = () => {
  const container = document.documentElement;

  if (!isFullscreen) {
    if (container.requestFullscreen) {
      container.requestFullscreen();
    } else if (container.mozRequestFullScreen) {
      container.mozRequestFullScreen();
    } else if (container.webkitRequestFullscreen) {
      container.webkitRequestFullscreen();
    } else if (container.msRequestFullscreen) {
      container.msRequestFullscreen();
    }
  } else {
    if (document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  }

  setIsFullscreen(!isFullscreen);
  isFullscreenRef.current = !isFullscreenRef.current;
};


// UseEffect to handle touches as well as mouse moves
  useEffect(() => {
    const handleTouchMove = (e) => {
      if (isDraggingRef.current) {
        e.preventDefault();
      }
    };
    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);




// UseEffect to handle soundtrack
  useEffect(() => {
    //console.log('soundRef.current:', soundRef.current);
    soundtrackRef.current.currentTime = 0;
    if (soundRef.current && !soundRef.current.paused) {
      //console.log('Playing audio...');
      soundtrackRef.current.loop = true; // Enable looping
      soundtrackRef.current.play().catch(error => console.error('Error playing audio:', error)); // Start playback and handle errors
    } 
    else{
      //console.log('Pausing audio...');
      soundtrackRef.current.pause(); // Pause audio if it's already playing and soundRef is false
    }
    return () => {
      if (soundRef.current && !soundRef.current.paused) {
        soundtrackRef.current.pause();
      }
    };
  }, [soundRef.current]);

  




  // ---MAIN GAME USEEFFECT---
  useEffect(() => {
    // AUDIO STUFF
    const randomIndexHoop = Math.floor(Math.random() * hoopSounds.length);
    const randomIndexCrowd = Math.floor(Math.random() * crowdSounds.length);
    // STUFF VISUAL
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    canvas.width = canvasSize.width;
    canvas.height = canvasSize.height;









   
    const shineOnCollision = () => {
      // Trigger shine effect on collision (adjust as needed)
      hitAnimationTimeRef.current = 30;
      if(soundRef.current)floorSound.play();
    };


    // ----------------COLLISION CHECKING FUNCTIONS---------------------
    const checkAndHandleWallCollision = () => {
      // Horizontal
      if (positionRef.current.x <= levelRef.current.ballsize/2 || positionRef.current.x >= canvas.width - levelRef.current.ballsize/2) {
        velocityRef.current.x *= -1;
        collidedWallRef.current.x = positionRef.current.x;
        collidedWallRef.current.y = positionRef.current.y;
        collidedWallRef.current.time = 30;
        setCollidedWall(true);

        shineOnCollision(); // Shine effect on collision
      }
      // Vertical
      // if (positionRef.current.y <= 0 || positionRef.current.y >= canvas.height - ballsize/2) {
      if (positionRef.current.y >= canvas.height - levelRef.current.ballsize/2) {
        velocityRef.current.y *= -1.00;
        collidedWallRef.current.x = positionRef.current.x;
        collidedWallRef.current.y = positionRef.current.y;
        collidedWallRef.current.time = 30;
        setCollidedWall(true);

        if (positionRef.current.y >= canvas.height - 2*levelRef.current.ballsize && Math.abs(velocityRef.current.y) <= 3) velocityRef.current.y /= 1.5 ;

        if (Math.abs(velocityRef.current.y) < 0.15 && Math.abs(velocityRef.current.x)<3) velocityRef.current.y=0;

        if (velocityRef.current.y!==0)shineOnCollision(); 
      }
    };


    const checkHoopCollision = () => {
      return (
        positionRef.current.x + levelRef.current.ballsize/2 > levelRef.current.hoop.x && // ball inside in x axis
        positionRef.current.y + levelRef.current.ballsize/2 > levelRef.current.hoop.y && // ball inside in y axis
        positionRef.current.y < levelRef.current.hoop.y + levelRef.current.hoop.height //ball inside height of hoop
      );
    };

    const ballGoesThroughCenter = () =>{
              return  positionRef.current.y + levelRef.current.ballsize/2 >= levelRef.current.hoop.y && positionRef.current.y <= levelRef.current.hoop.y + levelRef.current.hoop.height && positionRef.current.x >= levelRef.current.hoop.x + (4*levelRef.current.ballsize/9) && positionRef.current.x < levelRef.current.hoop.x + levelRef.current.hoop.width - (5*levelRef.current.ballsize/9)
    }

    const checkNetCollision = () => {
      return (
        positionRef.current.x + levelRef.current.ballsize/2 > levelRef.current.hoop.x &&
        positionRef.current.y + levelRef.current.ballsize/2 > levelRef.current.hoop.y &&
        positionRef.current.y > levelRef.current.hoop.y + levelRef.current.hoop.height
      );
    };

    const checkRectangleCollision = (rectangle) => {
      const ballRadius = levelRef.current.ballsize / 2;

      // Check if the ball collides with the rectangle
      if (
        positionRef.current.x + ballRadius > rectangle.x &&
        positionRef.current.x - ballRadius < rectangle.x + rectangle.width &&
        positionRef.current.y + ballRadius > rectangle.y &&
        positionRef.current.y - ballRadius < rectangle.y + rectangle.height
      ) {
        return true; // Collision detected
      }
    
      return false; // No collision
    };

    // Function to check collision between ball and obstacle
    const checkSphereCollision = (sphere) => {
      // Calculate distance between the center of the obstacle and the center of the ball
      const dx = sphere.x - positionRef.current.x;
      const dy = sphere.y - positionRef.current.y;
      const distance = Math.sqrt(dx * dx + dy * dy);

      // Check if the distance is less than the sum of the radii of the ball and the obstacle
      return distance < levelRef.current.ballsize/2 + sphere.radius;
};






 // ----------------COLLISION HANDLING FUNCTIONS---------------------

    const handleRectangleCollision = (rectangle) => {
      const ballRadius = levelRef.current.ballsize / 2;
      // horizontal wallhit
      if( (positionRef.current.x < rectangle.x || positionRef.current.x > rectangle.x + rectangle.width) && (positionRef.current.y > rectangle.y  && positionRef.current.y <= rectangle.y + rectangle.height)){
                
        velocityRef.current.x *= -1;

      
        collidedWallRef.current.x = positionRef.current.x;
        collidedWallRef.current.y = positionRef.current.y;
        collidedWallRef.current.time = 30;
        setCollidedWall(true);

        shineOnCollision(); // Shine effect on collision
      }
      // Vertical
      else{
        if(positionRef.current.x >= rectangle.x-ballRadius && positionRef.current.x < rectangle.x){
          velocityRef.current.x =- Math.random() * 3 ;
        }
        else if(positionRef.current.x > rectangle.x + rectangle.width && positionRef.current.x < rectangle.x + rectangle.width + ballRadius){
          velocityRef.current.x =+ Math.random() * 3;
        }

        velocityRef.current.y *= -1;

        collidedWallRef.current.x = positionRef.current.x;
        collidedWallRef.current.y = positionRef.current.y;
        collidedWallRef.current.time = 30;
        setCollidedWall(true);

        if (velocityRef.current.y!==0)shineOnCollision(); 
      }
  
    }

    const handleSphereCollision = (sphere) => {

      // // Trigger any additional collision effects or animations
      shineOnCollision(); // For example, shine effect on collision

      // Calculate the displacement vector from the center of the circle to the ball
      const displacementX = positionRef.current.x - sphere.x;
      const displacementY = positionRef.current.y - sphere.y;

      // Calculate the magnitude of the displacement vector
      const displacementMagnitude = Math.sqrt(displacementX ** 2 + displacementY ** 2);

      // Normalize the displacement vector to get the unit vector
      const normalizedDisplacementX = displacementX / displacementMagnitude;
      const normalizedDisplacementY = displacementY / displacementMagnitude;

      // Define the magnitude of the force to be applied
      const forceMagnitude = sphere.force; // Adjust as needed

      // Apply the force away from the center of the circle
      const forceX = normalizedDisplacementX * forceMagnitude;
      const forceY = normalizedDisplacementY * forceMagnitude;

      // Apply the calculated force to the ball (e.g., update its velocity)
      velocityRef.current.x += forceX;
      velocityRef.current.y += forceY;
      };


    const handleHoopCollision = () => {
      if (!collided) {
        // IF GOES THROUGH CENTER THE RIGHT WAY(DOWNWARD) - SCORED!
        if (ballGoesThroughCenter() && velocityRef.current.y > 0) {
          if(soundRef.current){
            swooshSound.play();
            crowdSounds[randomIndexCrowd].play();
          }
          scoreAnimationTimeRef.current=70;
          collided = true;
      
        
          setScore((prev) => prev + 100);
          scoreRef.current+=100;
          totalScoreRef.current = scoreRef.current + 100 * currentTryRef.current;
          if(levelRef.current.level + 1 < levels.length){
            // SET NEXT LEVEL UNLOCKED
            unlockedLevelsRef.current[levelRef.current.level+1] = true;
          }
          // Saving the unlocked levels to local storage
          localStorage.setItem('unlockedLevels', JSON.stringify(unlockedLevelsRef.current));
          setShowSuccessMessage(true);
        }
        // COLLIDED - BUT DID NOT SCORE
        else {
            // Sound effect for hoop hit
            if(soundRef.current)hoopSounds[randomIndexHoop].play();
            velocityRef.current.x *= -1;
            velocityRef.current.y *= -1;

            if(positionRef.current.x < levelRef.current.hoop.x){
              velocityRef.current.x -= Math.random()*3;
            }
            else if(positionRef.current.x > levelRef.current.hoop.x && positionRef.current.x < levelRef.current.hoop.x+(levelRef.current.hoop.width/2)){
              velocityRef.current.x += Math.random()*3;
            }
            else{
              velocityRef.current.x -= Math.random()*3;
            }
          // Ball hits the hoop from the side, bounce back
        }
      }
      else{
        if(positionRef.current.x > levelRef.current.hoop.x + levelRef.current.hoop.width/2){
          velocityRef.current.x += Math.random()/2;
          velocityRef.current.x *= -1;

        }
        else{
          velocityRef.current.x -= Math.random()/2;
          velocityRef.current.x *= -1;
        }

      }
    }




 // ----------------BALL POSITION UPDATE FUNCTION---------------------
    const updateBall = () => {
      // ---If draggig ball, just move to mouse position---
      if (isDraggingRef.current) {
        if(dragAndDropRef.current){
          positionRef.current.x = mouseCoordinatesRef.current.x;
          positionRef.current.y = mouseCoordinatesRef.current.y;
          velocityRef.current = { x: 0, y: 0 }; // Reset velocity when dragging
        }
        else{

        }
      } 
      // ---Else, calculate new position and apply all effects applicable for the next frame---
      else {
        // ---Update position based on velocity---
        positionRef.current.x += velocityRef.current.x;
        positionRef.current.y += velocityRef.current.y;

        // ---Friction effect---
        velocityRef.current.x *= levelRef.current.friction;
        velocityRef.current.y *= levelRef.current.friction;

        // ---Gravity effect---
        velocityRef.current.y += levelRef.current.gravityY;
        velocityRef.current.x += levelRef.current.gravityX;
        

        // ---Bounce off the walls---
        checkAndHandleWallCollision();



        // ---Check obstacles for each level
        if(levelRef.current.level==5){
          if(checkRectangleCollision(Level5Square))handleRectangleCollision(Level5Square);
        }
        else if(levelRef.current.level==6){
          if(checkRectangleCollision(Level6Square1))handleRectangleCollision(Level6Square1);
          if(checkRectangleCollision(Level6Square2))handleRectangleCollision(Level6Square2);
        }
        else if(levelRef.current.level==7){
          if(checkRectangleCollision(Level7Square1))handleRectangleCollision(Level7Square1);
          if(checkRectangleCollision(Level7Square2))handleRectangleCollision(Level7Square2);
        }
        else if(levelRef.current.level==8){
          if(checkRectangleCollision(Level8Square1))handleRectangleCollision(Level8Square1);
          if(checkRectangleCollision(Level8Square2))handleRectangleCollision(Level8Square2);
          if(checkRectangleCollision(Level8Square3))handleRectangleCollision(Level8Square3);
          if(checkRectangleCollision(Level8Square4))handleRectangleCollision(Level8Square4);
        }
        else if(levelRef.current.level==9){
          if(checkRectangleCollision(Level9Square1))handleRectangleCollision(Level9Square1);
          if(checkRectangleCollision(Level9Square2))handleRectangleCollision(Level9Square2);
          if(checkRectangleCollision(Level9Square3))handleRectangleCollision(Level9Square3);
        }
        else if(levelRef.current.level==10){
          if(checkSphereCollision(Level10Sphere))handleSphereCollision(Level10Sphere);
          if(checkRectangleCollision(Level10Square1))handleRectangleCollision(Level10Square1);
          if(checkRectangleCollision(Level10Square2))handleRectangleCollision(Level10Square2);
          if(checkRectangleCollision(Level10Square3))handleRectangleCollision(Level10Square3);
        }
        


        // ---Check for hoop collision---
        if (checkHoopCollision())handleHoopCollision();
        else collided = false;

      }
    };




    // ----------------MAIN DRAW FUNCTION---------------------
    const draw = () => {
      //---Clearing canvas---
      ctx.clearRect(0, 0, canvasSize.width, canvasSize.height);


      //---Reducing animation timers---
      hitAnimationTimeRef.current -= 1;
      scoreAnimationTimeRef.current -= 1;

      // Draw level
      drawLevel(ctx);


      // ---Draw scoreboard---
      ctx.fillStyle = '#FFF';
      ctx.font = '24px Arial';
      ctx.textAlign = 'center';
      ctx.fillText(`Score: ${scoreRef.current}`, canvasSize.width / 2, 50);

      // ---Draw touches---
      ctx.fillStyle = '#FFF';
      ctx.font = '20px Arial';
      ctx.textAlign = 'center';
      ctx.fillText(`Touches: ${currentTryRef.current!=null && currentTryRef.current}`, canvasSize.width - 70, 50);

      if(!startedRef.current){
        // ---Draw level title and text---
        ctx.fillStyle = '#FFF';
        ctx.font = '24px Arial';
        ctx.textAlign = 'center';
        ctx.fillText(`${levelRef.current.text}`, canvasSize.width / 2, canvasSize.height / 2);
        ctx.fillStyle = '#FFF';
        ctx.font = '16px Arial';
        ctx.textAlign = 'center';
        ctx.fillText(`${levelRef.current.subtext}`, canvasSize.width / 2, canvasSize.height / 2 + 30);
      }

      drawResetButton(ctx);

      // IF HOLDING, DRAW TRAJECTORY
      if(previewRef.current || levelRef.current.preview.value)drawTrajectory(ctx, positionRef.current, velocityRef.current);


      //if collided USE COLLIDED EFFECT
      if(collidedWallRef.current.time > 0){
        collidedWallRef.current.time--;
        drawCollisionEffect(ctx, collidedWallRef.current.x, collidedWallRef.current.y);
      }
      else{
        setCollidedWall(false);
      }

      // ---Draw +1 effect over hoop when scoring---
      const hoopLeft = levelRef.current.hoop.x+(3*levelRef.current.ballsize/8);
      const hoopRight = levelRef.current.hoop.x + levelRef.current.hoop.width - (3*levelRef.current.ballsize/8);
      if (((ballGoesThroughCenter() || scoreAnimationTimeRef.current>0)) && isDraggingRef.current===false) {
        drawScoringEffect(ctx,hoopLeft, hoopRight);
      }

      // draw arrow if higher than canvas
      if (positionRef.current.y <= 0 - levelRef.current.ballsize/2){
        drawArrow(ctx, positionRef.current.x, 50);
      }
      // else draw the ball normally
      else{
        drawBallWithTrail(ctx, 2);
      }
      
      // ---Draw hoop---
      drawHoop(ctx);

      // ---Draw net---
      drawNet(ctx);
    };


    const drawHoop = (ctx) => {
      ctx.fillStyle = 'red';
      ctx.fillRect(levelRef.current.hoop.x, levelRef.current.hoop.y, levelRef.current.hoop.width, levelRef.current.hoop.height);
    }

    const drawNet = (ctx) => {
      const netLength = 2*levelRef.current.hoop.width/3;
      ctx.beginPath();
      //    Vertical
      //    Right and Left lines
      ctx.moveTo(levelRef.current.hoop.x, levelRef.current.hoop.y + levelRef.current.hoop.height); 
      ctx.lineTo(levelRef.current.hoop.x + levelRef.current.hoop.width/8, levelRef.current.hoop.y + levelRef.current.hoop.height + netLength); // Extend downwards
    
      ctx.moveTo(levelRef.current.hoop.x + levelRef.current.hoop.width - levelRef.current.hoop.width/7, levelRef.current.hoop.y + levelRef.current.hoop.height); 
      ctx.lineTo(levelRef.current.hoop.x + levelRef.current.hoop.width - levelRef.current.hoop.width/5, levelRef.current.hoop.y + levelRef.current.hoop.height + netLength); // Extend downwards
      //    Middle lines
      ctx.moveTo(levelRef.current.hoop.x + levelRef.current.hoop.width - 2*levelRef.current.hoop.width/5, levelRef.current.hoop.y + levelRef.current.hoop.height);
      ctx.lineTo(levelRef.current.hoop.x + levelRef.current.hoop.width - 5*levelRef.current.hoop.width/12, levelRef.current.hoop.y + levelRef.current.hoop.height + netLength); // Extend downwards

      ctx.moveTo(levelRef.current.hoop.x + levelRef.current.hoop.width - 3*levelRef.current.hoop.width/5, levelRef.current.hoop.y + levelRef.current.hoop.height); 
      ctx.lineTo(levelRef.current.hoop.x + levelRef.current.hoop.width - 3*levelRef.current.hoop.width/5, levelRef.current.hoop.y + levelRef.current.hoop.height + netLength); // Extend downwards

      ctx.moveTo(levelRef.current.hoop.x + levelRef.current.hoop.width - 4*levelRef.current.hoop.width/5, levelRef.current.hoop.y + levelRef.current.hoop.height); 
      ctx.lineTo(levelRef.current.hoop.x + levelRef.current.hoop.width - 8*levelRef.current.hoop.width/11, levelRef.current.hoop.y + levelRef.current.hoop.height + netLength); // Extend downwards

      // Horizontal
      //    Top and Bottom lines
      ctx.moveTo(levelRef.current.hoop.x, levelRef.current.hoop.y + levelRef.current.hoop.height); 
      ctx.lineTo(levelRef.current.hoop.x + levelRef.current.hoop.width, levelRef.current.hoop.y + levelRef.current.hoop.height); // Extend sideways
    
      ctx.moveTo(levelRef.current.hoop.x + levelRef.current.hoop.width/9 , levelRef.current.hoop.y + levelRef.current.hoop.height + 8*netLength/10); 
      ctx.lineTo(levelRef.current.hoop.x + levelRef.current.hoop.width - levelRef.current.hoop.width/5 , levelRef.current.hoop.y + levelRef.current.hoop.height + 8*netLength/10); // Extend sideways
      //    Moddle lines
      ctx.moveTo(levelRef.current.hoop.x + levelRef.current.hoop.width/14 , levelRef.current.hoop.y + levelRef.current.hoop.height + 6*netLength/10); 
      ctx.lineTo(levelRef.current.hoop.x + levelRef.current.hoop.width - levelRef.current.hoop.width/5 , levelRef.current.hoop.y + levelRef.current.hoop.height + 6*netLength/10); // Extend sideways

      ctx.moveTo(levelRef.current.hoop.x + levelRef.current.hoop.width/18 , levelRef.current.hoop.y + levelRef.current.hoop.height + 4*netLength/10); 
      ctx.lineTo(levelRef.current.hoop.x + levelRef.current.hoop.width - levelRef.current.hoop.width/6 , levelRef.current.hoop.y + levelRef.current.hoop.height + 4*netLength/10); // Extend sideways

      ctx.moveTo(levelRef.current.hoop.x + levelRef.current.hoop.width/30 , levelRef.current.hoop.y + levelRef.current.hoop.height + 2*netLength/10); 
      ctx.lineTo(levelRef.current.hoop.x + levelRef.current.hoop.width - levelRef.current.hoop.width/7 , levelRef.current.hoop.y + levelRef.current.hoop.height + 2*netLength/10); // Extend sideways

      ctx.strokeStyle = '#FFF'; // Adjust color as needed
      ctx.lineWidth = 2.2; // Adjust line width as needed
      ctx.stroke();
    }

    const drawLevel = (ctx) => {
      switch (levelRef.current.level){
        case 5:
          drawRectangle(ctx, Level5Square);
          break;
        case 6:
          drawRectangle(ctx, Level6Square1);
          drawRectangle(ctx, Level6Square2);
          break;
        case 7:
          drawRectangle(ctx, Level7Square1);
          drawRectangle(ctx, Level7Square2);
          break;
        case 8:
          drawRectangle(ctx, Level8Square1);
          drawRectangle(ctx, Level8Square2);
          drawRectangle(ctx, Level8Square3);
          drawRectangle(ctx, Level8Square4);
          break;
        case 9:
          drawRectangle(ctx, Level9Square1);
          drawRectangle(ctx, Level9Square2);
          drawRectangle(ctx, Level9Square3);
          break;
        case 10:
          drawSphere(ctx, Level10Sphere);
          drawRectangle(ctx, Level10Square1);
          drawRectangle(ctx, Level10Square2);
          drawRectangle(ctx, Level10Square3);
          break;
        default:
          break;
      }
   
    }

    const drawRectangle = (ctx, rectangle) => {
      ctx.fillStyle = 'black'; // Set fill color
      ctx.strokeStyle = "#FFF981"; // Set stroke color to yellow
      ctx.fillRect(rectangle.x, rectangle.y, rectangle.width, rectangle.height); // Draw rectangle
      ctx.strokeRect(rectangle.x, rectangle.y, rectangle.width, rectangle.height); // Draw rectangle
    };

    const drawBallWithTrail = (ctx,trailLength) => {
      //    Color change based on hitAnimationTimeRef.current
      const change = Math.min(1, hitAnimationTimeRef.current>0?hitAnimationTimeRef.current:0 / 20);
      //    Scale change based on hitAnimationTimeRef.current
      const hitScale = 1 + Math.abs(hitAnimationTimeRef.current>0?hitAnimationTimeRef.current / 600:0);
      //    Image size based on ballsize and scaling effect
      const imageSize = levelRef.current.ballsize * hitScale;

      // ---Draw fading trail of balls---
      for (let i = 0; i < trailLength; i++) {
        const alpha = 1 - (i / trailLength) * change; // Decrease alpha for each previous position based on change
        ctx.fillStyle = `rgba(211, ${84 + change * 10}, 0, ${alpha})`; // Adjust color and alpha based on change
        ctx.beginPath();
        ctx.arc(positionRef.current.x - i * velocityRef.current.x, positionRef.current.y - i * velocityRef.current.y, imageSize/2, 0, Math.PI * 2);
        ctx.fill();
      }
      // -------------------------------

        //    Generate fillColor for current frame
        const fillColor = `rgba(211, ${84 + change * 10} , 0, 1)`;
        ctx.fillStyle = fillColor;

        try {
          ctx.save(); // Save the current state of the context
    
          // Translate to the center of the image, rotate, and then draw
          ctx.translate(positionRef.current.x, positionRef.current.y);
          const rotationAngle = -velocityRef.current.x * 3 ; // Adjust the rotation speed as needed
          ctx.rotate(rotationAngle);
          ctx.drawImage(ballImage, -imageSize / 2, -imageSize / 2, imageSize, imageSize);
    
          ctx.restore(); // Restore the saved state to prevent the rotation from affecting other elements
        } catch (error) {
          // console.error('Error drawing and rotating ball image:', error);
          console.error('Error drawing ball:', error);
        }
    };

    
    // Function to draw the obstacle on the canvas
    const drawSphere= (ctx,sphere) => {
      ctx.beginPath();
      ctx.arc(sphere.x, sphere.y, sphere.radius, 0, Math.PI * 2);
      const gradient1 = ctx.createRadialGradient(sphere.x, sphere.y, 0, sphere.x, sphere.y, sphere.radius);
      gradient1.addColorStop(0, `rgba(255, 0, 0, 0.8)`); // Center with variable opacity
      gradient1.addColorStop(1, `rgba(255, 218, 99, 0)`); // Outer edge with zero opacity
      ctx.fillStyle = gradient1;
      ctx.fill();
      // ctx.closePath();


      // Create a radial gradient for the circle fill style
      const gradient = ctx.createRadialGradient(sphere.x, sphere.y, 0, sphere.x, sphere.y, sphere.radius);

      // Define animation parameters
      const animationFrames = 1000; // Total frames for the animation
      const fadeInFrames = 500; // Frames for fade in

      // Calculate animation factors
      const fadeInFactor = Math.min(1, loopFactor / fadeInFrames);
      const fadeOutFactor = Math.max(0, 1 - loopFactor / fadeInFrames);

      // Calculate opacity and radius based on animation factors
      const opacity = fadeInFactor * (1 - fadeOutFactor); // Opacity increases from 0 to 1 and then decreases from 1 to 0
      const radius = sphere.radius * (1 * (1 - Math.abs(fadeOutFactor-1))); // Radius increases from 0 to half the original radius and then decreases back to 0

      const red = (255 * opacity ) % 255;
      // Add color stops to the gradient based on opacity
      gradient.addColorStop(0, `rgba(255, ${red}, ${red}, ${opacity})`); // Center with variable opacity
      gradient.addColorStop(1, `rgba(255, ${red}, ${red}, 0)`); // Outer edge with zero opacity

      // Set the fill style to the radial gradient
      ctx.fillStyle = gradient;

      // Draw the circle
      ctx.beginPath();
      ctx.arc(sphere.x, sphere.y, radius, 0, Math.PI * 2);
      ctx.fill();

      loopFactor--;
      if(loopFactor<0)loopFactor=1000;
      
    
    };

    const drawTrajectory = (ctx, position, velocity) => {
      const numPoints = levelRef.current.preview.numPoints;
      const timeStep = levelRef.current.preview.timeStep;
      const initialAlpha = 100.0; // Initial alpha value
      const alphaStep = initialAlpha / numPoints; // Alpha reduction step

    
      ctx.beginPath();
    
      for (let i = 0; i < numPoints; i++) {
        const time = i * timeStep;
        const x = position.x + velocity.x * time + 0.8*levelRef.current.gravityX * time ** 1.995;
        const y = position.y + velocity.y * time + 0.8*levelRef.current.gravityY * time ** 1.995; // Assuming gravity (9.8 m/s^2)

        // Calculate alpha based on point position
        const alpha = Math.max(initialAlpha - i * alphaStep/2, 0); // Ensure alpha doesn't go below 0
        // Set the color with updated alpha
        ctx.strokeStyle = `rgba(169, 169, 169, ${alpha})`;

        // Draw the point
        if (i === 0) {
          ctx.moveTo(x, y); // Move to the initial point 
        } else {
          ctx.lineTo(x, y);
        }
      }
      ctx.stroke();
      ctx.closePath();
    };

    const drawCollisionEffect = (ctx, x, y) => {
      const hitScale = 1 + Math.abs(collidedWallRef.current.time>0?collidedWallRef.current.time :0);
      const gradient = ctx.createRadialGradient(
        x, y, 0,
        x, y, hitScale
      );
    
      gradient.addColorStop(0, 'rgba(255, 255, 255, 1)'); // Fully opaque white
      gradient.addColorStop(1, 'rgba(255, 255, 255, 0)'); // Fully transparent white
    
      ctx.beginPath();
      ctx.arc(x, y, 40 * hitScale, 0, 2 * Math.PI);
      ctx.fillStyle = gradient;
      ctx.fill();
      ctx.closePath();
    };

    const drawPauseSign = (ctx, canvasWidth, canvasHeight) => {
      const fontSize = 40;
      const pauseText = 'PAUSE';
    
      // Set font properties
      ctx.font = `${fontSize}px Arial`;
      ctx.fillStyle = 'rgba(245, 0, 0, 0.8)'; // Red color with some transparency
    
      // Calculate text width to center it on the canvas
      const textWidth = ctx.measureText(pauseText).width;
      const x = (canvasWidth) / 2;
      const y = canvasHeight / 2;
    
      // Draw the "PAUSE" text
      ctx.fillText(pauseText, x, y);
    };

    const drawArrow = (ctx, x, y) => {
      const arrowWidth = 20;
      const arrowHeight = 30;
    
      ctx.beginPath();
      ctx.moveTo(x - arrowWidth / 2, y);
      ctx.lineTo(x + arrowWidth / 2, y);
      ctx.lineTo(x, y - arrowHeight);
      ctx.closePath();
    
      ctx.fillStyle = 'red'; // Set the arrow color
      ctx.fill();
    };

    const drawResetButton = (ctx) => {
      const buttonWidth = 60;
      const buttonHeight = 30;
      const buttonPadding = 20;
      const buttonText = "Reset";
    
      // Set button style
      ctx.fillStyle = "#FFF981";
      ctx.fillRect(buttonPadding, buttonPadding, buttonWidth, buttonHeight);
    
      // Set text style
      ctx.fillStyle = "black";
      ctx.font = "20px Arial";
      
      // Calculate text position to center it inside the button
      const textX = buttonPadding + (buttonWidth) / 2;
      const textY = buttonPadding + (buttonHeight + 15) / 2; // Assuming font size is 20px
    
      // Draw text
      ctx.fillText(buttonText, textX, textY);
    };

    const drawScoringEffect = (ctx, hoopLeft, hoopRight)=>{
      ctx.save();
      ctx.fillStyle = "#FFF981";
      ctx.globalAlpha = Math.min(1, 2000); // Adjust the duration as needed
      ctx.font = `${scoreAnimationTimeRef.current/2}px Arial`;
      ctx.fillText(`*swoosh*`, (hoopLeft + hoopRight)/2, levelRef.current.hoop.y - 40);
      ctx.restore();
    }








    // ---Mouse move handlers---

    // Mouse start/down handler
    const handleMouseDown = (e) => {
      const rect = canvas.getBoundingClientRect();
      // const mouseX = e.clientX - rect.left;
      // const mouseY = e.clientY - rect.top;

      const scaleX = canvasRef.current.width / rect.width;
      const scaleY = canvasRef.current.height / rect.height;

      const mouseX = (e.clientX - rect.left) * scaleX;
      const mouseY = (e.clientY - rect.top) * scaleY;

      
      // Check if the mouse is inside the ball
      if (
      //   mouseX >= positionRef.current.x - (levelRef.current.ballsize + 50) &&
      //   mouseX <= positionRef.current.x + (levelRef.current.ballsize + 50) &&
      //   mouseY >= positionRef.current.y - (levelRef.current.ballsize + 50) &&
      //   mouseY <= positionRef.current.y + (levelRef.current.ballsize + 50) && 
      currentTryRef.current > 0
      ) {
        currentTryRef.current--;
        startedRef.current=true;
        setStarted(true);
        isDraggingRef.current = true;
        dragStartRef.current = { x: mouseX, y: mouseY };
        if(dragAndDropRef.current){
          positionRef.current.x = mouseX;
          positionRef.current.y = mouseY;
        }
        else{
          velocityRef.current = { x: 0, y: 0 };
        }
      }
      

      // Define button position and size
      const buttonX = 20; // X-coordinate of the top-left corner of the button
      const buttonY = 20; // Y-coordinate of the top-left corner of the button
      const buttonWidth = 60;
      const buttonHeight = 30;

      // Check if the click is inside the button bounds
      if (
        mouseX >= buttonX &&
        mouseX <= buttonX + buttonWidth &&
        mouseY >= buttonY &&
        mouseY <= buttonY + buttonHeight
      ) {
        // Reset action
        //console.log("Reset button clicked!");
        positionRef.current.x = levelRef.current.startPositionX;
        positionRef.current.y = levelRef.current.startPositionY;
        startedRef.current = false;
        scoreRef.current = 0;
        currentTryRef.current = levelRef.current.touches;
        setStarted(false);
        setScore(0);
      }
    };

    // Mouse move handler
    const handleMouseMove = (e) => {
      const rect = canvas.getBoundingClientRect();
      const scaleX = canvasRef.current.width / rect.width;
      const scaleY = canvasRef.current.height / rect.height;

      const mouseX = (e.clientX - rect.left) * scaleX;
      const mouseY = (e.clientY - rect.top) * scaleY;
      // const mouseX = e.clientX - rect.left;
      // const mouseY = e.clientY - rect.top;
      if (isDraggingRef.current) {
        // Calculate the difference from the drag start
        const dx = mouseX - dragStartRef.current.x;
        const dy = mouseY - dragStartRef.current.y;
        // Store the velocity for use after releasing the mouse
        while (Math.abs(velocityRef.current.x) >= 15 || Math.abs(velocityRef.current.y) >= 15){
          velocityRef.current.x/=2;
          velocityRef.current.y/=2;
        }

        if(dragAndDropRef.current){
          velocityRef.current = { x: dx, y: dy };
          // Update ball position based on the difference
          positionRef.current.x += dx;
          positionRef.current.y += dy;
          
          // Update the drag start position
          dragStartRef.current = { x: mouseX, y: mouseY };
        }
        else{    
          velocityRef.current = { x: -dx/10, y: -dy/10 };
          if (Math.abs(velocityRef.current.x) >= 15)velocityRef.current.x>0?velocityRef.current.x=20:velocityRef.current.x=-20;
          if (Math.abs(velocityRef.current.y) >= 15)velocityRef.current.y>0?velocityRef.current.y=20:velocityRef.current.y=-20;
          // while (Math.abs(velocityRef.current.x) >= 15 || Math.abs(velocityRef.current.y) >= 15){
          //   velocityRef.current.x/=2;
          //   velocityRef.current.y/=2;
          // }
          setPreview(true);
          previewRef.current = true;
        }
        
      }
      // Update mouse coordinates
      mouseCoordinatesRef.current = { x: mouseX, y: mouseY };
    };


    // Mouse stop/up handler
    const handleMouseUp = () => {
      isDraggingRef.current = false;
      previewRef.current = false;
      setPreview(false);
    };







    // ---Touch handlers---

    // Touch start handler
    const handleTouchStart = (e) => {
      const rect = canvas.getBoundingClientRect();
      const scaleX = canvasRef.current.width / rect.width;
      const scaleY = canvasRef.current.height / rect.height;

      const touchX = (e.touches[0].clientX - rect.left) * scaleX;
      const touchY = (e.touches[0].clientY - rect.top) * scaleY;

      // Check if the touch is inside the ball
      if (currentTryRef.current > 0
      ) {
        currentTryRef.current--;
        startedRef.current=true;
        setStarted(true);
        isDraggingRef.current = true;
        dragStartRef.current = { x: touchX, y: touchY };
        if(dragAndDropRef.current){
          positionRef.current.x = touchX;
          positionRef.current.y = touchY;
        }
        else{
          velocityRef.current = { x: 0, y: 0 };
        }
      }
    };


    // Touch move handler
    const handleTouchMove = (e) => {
      const rect = canvas.getBoundingClientRect();
      const scaleX = canvasRef.current.width / rect.width;
      const scaleY = canvasRef.current.height / rect.height;

      const touchX = (e.touches[0].clientX - rect.left) * scaleX;
      const touchY = (e.touches[0].clientY - rect.top) * scaleY;
      // const mouseX = e.clientX - rect.left;
      // const mouseY = e.clientY - rect.top;
      if (isDraggingRef.current) {
        // Calculate the difference from the drag start
        const dx = touchX - dragStartRef.current.x;
        const dy = touchY - dragStartRef.current.y;
        // Store the velocity for use after releasing the mouse
        while (Math.abs(velocityRef.current.x) >= 15 || Math.abs(velocityRef.current.y) >= 15){
          velocityRef.current.x/=2;
          velocityRef.current.y/=2;
        }

        if(dragAndDropRef.current){
          velocityRef.current = { x: dx, y: dy };
          // Update ball position based on the difference
          positionRef.current.x += dx;
          positionRef.current.y += dy;
          
          // Update the drag start position
          dragStartRef.current = { x: touchX, y: touchY };
        }
        else{    
          velocityRef.current = { x: -dx/10, y: -dy/10 };
          if (Math.abs(velocityRef.current.x) >= 15)velocityRef.current.x>0?velocityRef.current.x=20:velocityRef.current.x=-20;
          if (Math.abs(velocityRef.current.y) >= 15)velocityRef.current.y>0?velocityRef.current.y=20:velocityRef.current.y=-20;
          setPreview(true);
          previewRef.current = true;
        }
        
      }
      // Update mouse coordinates
      mouseCoordinatesRef.current = { x: touchX, y: touchY };
    };

    // Touch end handler
    const handleTouchEnd = () => {
      isDraggingRef.current = false;
      setPreview(false);
      previewRef.current = false;
    };


    // ---Add event listeners to canvas---
    // Add mouse handlers
    canvas.addEventListener('mousedown', handleMouseDown);
    canvas.addEventListener('mousemove', handleMouseMove);
    canvas.addEventListener('mouseup', handleMouseUp);
    // Add touch handlers
    canvas.addEventListener('touchstart', handleTouchStart);
    canvas.addEventListener('touchmove', handleTouchMove);
    canvas.addEventListener('touchend', handleTouchEnd);





    // ---Animation frame---
    const animationFrame = () => {
      draw();
      if (startedRef.current && !pausedRef.current) {
        updateBall();
        requestAnimationFrame(animationFrame);
        return;
      }
      if(pausedRef.current)drawPauseSign(ctx, canvasSize.width, canvasSize.height);
      requestAnimationFrame(animationFrame);
    };


    // ---Start animating---
    draw();
    animationFrame();
    ballImage.onload = () => draw();


    // ---Cleanup event listeners on component unmount---
    return () => {
      canvas.removeEventListener('mousedown', handleMouseDown);
      canvas.removeEventListener('mousemove', handleMouseMove);
      canvas.removeEventListener('mouseup', handleMouseUp);

      canvas.removeEventListener('touchstart', handleTouchStart);
      canvas.removeEventListener('touchmove', handleTouchMove);
      canvas.removeEventListener('touchend', handleTouchEnd);
    };
  }, [gravityX , gravityY, startedRef, pausedRef, soundRef, scoreRef, dragAndDropRef, collidedWallRef, levelRef]);
  


  // ---RETURN GAME COMPONENT---
  return (
    <div>

   
 
      <div className='container justify-content-center game-container'>
        <div className='menu-container row' width={"auto"} height={"auto"}>

          
          <div className={`btn col-4 ${pausedRef.current==true?"btn-outline-success":"btn-outline-warning"}`} onClick={()=>handlePaused()}>{pausedRef.current==true?<FontAwesomeIcon icon={faPlay} />:<FontAwesomeIcon icon={faPause} />}</div> 
          
          <div className={`btn col-4 ${soundRef.current==true?"btn-outline-success":"btn-outline-danger"}`} onClick={()=>handleSound()}> {soundRef.current==true?<FontAwesomeIcon icon={faVolumeUp} />:<FontAwesomeIcon icon={faVolumeMute} />}</div> 
          {/* <div className={`btn col-3 ${dragAndDropRef.current==true?"btn-outline-success":"btn-outline-danger"}`} onClick={()=>handleDragAndDrop()}><FontAwesomeIcon icon={faHand} /></div>  */}
          
          <div className='btn col-4 btn-outline-success' onClick={()=>toggleFullscreen()}>{isFullscreenRef.current==false?<FontAwesomeIcon icon={faExpand} />:<FontAwesomeIcon icon={faCompress} />}</div> 
        {/* <div className="circle-container col-6">
            <span className='gravity-text'>GRAVITY</span>
            <div className='btn btn-outline-warning up' onClick={()=>gravityUp()}>↑</div>
            <div className='btn btn-outline-warning down' onClick={()=>gravityDown()}>↓</div>
            <div className='btn btn-outline-warning left' onClick={()=>gravityLeft()}>←</div>
            <div className='btn btn-outline-warning right' onClick={()=>gravityRight()}>→</div>
          </div> */}
      </div>

      <div className='menu-container row' width={"auto"} height={"auto"}>
          {levels.map((level, index)=>{
            if(level.level==0)return;
            return(
              <div key={index} className={`btn btn-sm col-1 btn-outline-success ${!unlockedLevelsRef.current[index] && 'disabled'}`} onClick={()=>handleLevelChange(index)}>{level.level}</div>
            );})}
      </div>


      <div className='game-div'>
        <canvas className="row" ref={canvasRef} style={{ border: '2px solid #FFF981', width:"100%" ,maxWidth: '100%',pointerEvents: 'auto' }} />
        {showSuccessMessage && <SuccessMessage score={totalScoreRef.current} touches={currentTryRef.current} points={scoreRef.current} onNextLevel={handleNextLevel}/>}
      </div>
      

      </div>
        

      <br/><br/><br/><br/>
    </div>
  );
};



export default HoopGame;
