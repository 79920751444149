import React from 'react';
import "./Home.css";
import { useState, useEffect} from 'react';
import {motion} from 'framer-motion';

function Home(){


  // eslint-disable-next-line no-unused-vars
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // After the component is mounted, set isVisible to true
    setIsVisible(true);

    // Cleanup function for when the component is about to unmount
    return () => {
      setIsVisible(false);
    };
  }, []);


  const dataText = `I'm Kostas, a Full-Stack Developer with expertise in crafting dynamic web and Android applications.\n
  My passion lies in the seamless fusion of creativity and functionality to deliver impactful solutions.`;
  
    return (
    <div> 
     <br/>
      <motion.div initial={{opacity:0 , transition: { duration: 0.1}}} animate={{opacity:100, transition: { duration: 0.5, delay:0 , ease: "easeInOut"}}} exit={{opacity:0, transition: { duration:0.2, ease: "easeInOut" }}} className={`container-fluid`}>
    
        <motion.div initial={{opacity:0, transition: { duration: 0.1}}} animate={{opacity:100, transition: { duration: 1.5, delay:0.7 , ease: "easeInOut"}}} exit={{opacity:0, scale:3, transition: { duration: 0.2, ease: "easeInOut" }}} className="container-fluid">
          <div className='container-fluid'>
          <motion.div 
          initial={{opacity:0 , scale:1, transition: { duration: 0.1, delay:0.8}}} 
          animate={{opacity:100,scale:1, transition: { duration: 0.5, delay:0 , ease: "easeInOut"}}} 
          exit={{opacity:0,scale:3, transition: { duration:0.2, ease: "easeInOut" }}}>
                    <div className='h1 fw-light welcome'>Welcome</div>
          </motion.div>
            <br/>
            <div className='row align-items-center justify-content-center description'>
              <div className='col-md-12'>
                <div className='h5 fw-light text-decoration description d-flex' data-text={dataText}>{dataText}</div>
              </div>
            </div>
          </div>
        </motion.div>

      </motion.div>
      


    </div>

    );
    
  }
export default Home;