import React, { useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { AnimatePresence, motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTerminal } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import './Web.css';

function Web() {
    const [detailsReact, setDetailsReact] = useState(false);
    const [detailsWordpress, setDetailsWordpress] = useState(false);

    const propsSlideRightOp = useSpring({
        from: { opacity: 0, y: '-100%' },
        to: { opacity: 1, y: '0%' },
        delay: 500,
    });

    const handleDetailsReact = () => {
        setDetailsWordpress(false);
        setDetailsReact(!detailsReact);
    };

    const handleDetailsWordpress = () => {
        setDetailsReact(false);
        setDetailsWordpress(!detailsWordpress);
    };

    return (
        <div>
            <motion.div
                exit={{ opacity: 0, transition: { duration: 0.3, ease: 'easeInOut' } }}
                className="container"
            >
                <motion.div
                    exit={{ opacity: 0, transition: { duration: 0.2, ease: 'easeInOut' } }}
                >
                    <animated.div style={propsSlideRightOp}>
                        <div className="row align-items-center glitchy-text">
                            <div className="h1 fw-light glitchy-text"  data-text="Web Development &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;">
                                Web Development &nbsp;
                                <FontAwesomeIcon icon={faTerminal} style={{ color: '#ffffff' }} />
                            </div>
                        </div>
                    </animated.div>
                </motion.div>

                <br />

                <motion.div
                    key="portfolio-body"
                    initial={{ opacity: 0, transition: { duration: 0.1 } }}
                    animate={{ opacity: 100, y: '0%', scale: 1, transition: { duration: 0.5, delay: 0.4, ease: 'easeInOut' } }}
                    exit={{ opacity: 0, scale: 1, x:"-50vw" , transition: { duration: 0.4, ease: 'easeInOut' } }}
                    className="dots-div mx-auto"
                >
                    <div className="align-items-center justify-content-center">
                        <motion.div
                            className="my-weird-body"
                            initial={{ opacity: 0, transition: { duration: 0.1 } }}
                            animate={{ opacity: 100, transition: { duration: 0.7, delay: 0.1, ease: 'easeInOut' } }}
                            exit={{ opacity: 0, transition: { duration: 0.2, ease: 'easeInOut' } }}
                        >
                            <div className="row m-auto">

                                <div className="col">

                                    <div className="fw-light">
                                        <h3>Website Portfolio</h3>
                                        <div className="row justify-content-center">
                                            <button
                                                className={`btn btn-outline-light portfolio-btn ${detailsReact ? 'btn-secondary' : ''}`}
                                                onClick={handleDetailsReact}
                                            >
                                                <div className="typewriter-text" data-text="React.js">
                                                    React.js
                                                </div>
                                            </button>
                                        </div>
                                        <div className="row justify-content-center">
                                            <button
                                                className={`btn btn-outline-light portfolio-btn ${detailsWordpress ? 'btn-secondary' : ''}`}
                                                onClick={handleDetailsWordpress}
                                            >
                                                <div className="typewriter-text" data-text="WordPress">
                                                    WordPress
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <h3>&nbsp;</h3>

                                        
                                        <br></br><br></br>
                                        <AnimatePresence>
                                        {detailsReact&&
                                        
                                        <motion.div initial={{ opacity: 0,scale:0, transition: { duration: 0.1 } }}
                                        animate={{ opacity: 100, y: '0%', scale: 1, transition: { duration: 0.5, delay: 0.4, ease: 'backInOut' } }}
                                        exit={{ opacity: 0, scale: 1, x:"50vw" , transition: { duration: 0.4, ease: 'backInOut' } }} className="webOption p-3">
                                            
                                            <div className="fw-light">
                                                
                                                <a 
                                                href="https://www.ek-creative.gr" 
                                                rel="noreferrer"
                                                target="_blank"
                                                >ek-creative.gr</a>
                                      
                                            </div>
                                        </motion.div>
                                        }
                                        </AnimatePresence>
                                        <AnimatePresence>
                                        {detailsWordpress&&
                                        <motion.div initial={{ opacity: 0,scale:0, transition: { duration: 0.1 } }}
                                        animate={{ opacity: 100, y: '0%', scale: 1, transition: { duration: 0.5, delay: 0.4, ease: 'backInOut' } }}
                                        exit={{ opacity: 0, scale: 1, x:"50vw" , transition: { duration: 0.4, ease: 'backInOut' } }} className="webOption p-3">
                                            <div className="fw-light">
                                                <a
                                                    href="https://www.georgopoulostransfers.gr"
                                                    rel="noreferrer"
                                                    target="_blank"
                                                >georgopoulostransfers.gr</a>
                                            </div>
                                        </motion.div>
                                        }
                                        </AnimatePresence>
                                </div>
                                <br />
                                <br />
                            </div>
                        </motion.div>
                        <br />
                        <br />
                        <br />

                        <div className="row align-items-center">
                            <div className="h1 fw-light">
                                Want your own? Feel free to{' '}
                                <Link
                                    to="/contact"
                                    style={{ color: 'inherit', textDecoration: 'green wavy underline' }}
                                >
                                    <motion.div
                                        whileHover={{ scale: 1.2 }}
                                        className="h2 fw-light text-decoration hover-zoom"
                                        style={{ '--fa-animation-delay': '0.1s' }}
                                    >
                                        contact me!
                                    </motion.div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </motion.div>

            <br />
            <br />
            <br />
        </div>
    );
}

export default Web;
