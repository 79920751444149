import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, useLocation, Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { AnimatePresence, motion } from 'framer-motion';
import "./Navbar.css";
import logo from '../images/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTerminal, faUserClock, faGamepad, faContactCard, faMobileAndroid } from '@fortawesome/free-solid-svg-icons';

const Navbar = ( {onTabChange} ) => {
    const [curLocation, setLocation] = useState("/");
    const location = useLocation();
    const inputRef = useRef(null);
    const [textColor, setTextColor] = useState('black');
    const [backgroundColor, setBackgroundColor] = useState('transparent');
    const [isCollapsed, setIsCollapsed] = useState(false);

    function toggleNavbar() {
        setIsCollapsed(!isCollapsed);
    }

    function collapseHandle() {
        if (!isCollapsed && window.innerWidth < 992) { // Adjust this if your breakpoint is different
            setIsCollapsed(true);
        }
    }

    useEffect(() => {
        setLocation(location.pathname);
        onTabChange(location.pathname);

        if (location.pathname === '/bio') {
            setTextColor('black');
            setBackgroundColor('rgba(255, 255, 255, 0.5)');
        } else if (location.pathname === '/web') {
            setTextColor('white');
            setBackgroundColor('rgba(255, 255, 255, 0.05)');
        } else if (location.pathname === '/android') {
            setTextColor('black');
            setBackgroundColor('rgba(255, 255, 255, 0.3)');
        } else if (location.pathname === '/contact') {
            setTextColor('black');
            setBackgroundColor('rgba(255, 255, 255, 0.2)');
        } else if (location.pathname === '/games') {
            setTextColor('#FFF981');
            setBackgroundColor('rgba(0, 200, 2000, 0.1)');
        } else {
            setTextColor('black');
            setBackgroundColor('rgba(213, 184, 255, 0.7)');
        }
    }, [location]);

    return (
        <motion.div className='navbar-class' style={{ backgroundColor, color: textColor, transition: 'background-color 0.5s easeInOut' , zIndex:10000}}
        initial={{opacity:0, transition: { duration: 0.1, ease: "easeInOut"}}} 
        animate={{opacity:100,x:'0%', transition: { duration: 0.8 , ease: "easeInOut"}}} 
        exit={{opacity:0, x:'50vw', transition: { duration: 0.3, ease: "easeInOut" }}}>
            <nav className="navbar navbar-expand-lg bg-body-tertiary" role="navigation" style={{ zIndex:10000}}>
                <div className="container">
                    <Link to="/" className="nav-link">
                        <img className="img-fluid logo" src={logo} alt="" />
                        
                    </Link>
                    <AnimatePresence>
                    {
                        (location.pathname === '/bio')?<motion.div className='p-2 fw-light border-5 selected-navbar-indicator' style={{ backgroundColor, color: textColor, transition: 'background-color 0.5s easeInOut' , zIndex:10000}}
                                                                initial={{opacity:0,scale:0, transition: { duration: 0.1, ease: "backInOut"}}} 
                                                                animate={{opacity:100,scale:1, transition: { duration: 0.4 , ease: "backInOut"}}} 
                                                                exit={{opacity:0,scale:5, transition: { duration: 0.4, ease: "easeInOut" }}}>
                                                            <div className='row'>
                                                                <div className='col-8'>Biography</div>
                                                                <FontAwesomeIcon icon={faUserClock} style={{ color: '#000000' , marginTop: '3px', marginLeft:'-10px'  }} className='col'/>
                                                            </div>
                                                        </motion.div>:
                        (location.pathname === '/web')?<motion.div className='p-2 fw-light border-5 glitchy-text selected-navbar-indicator' style={{ backgroundColor, color: textColor, transition: 'background-color 0.5s easeInOut' , zIndex:10000}}
                                                                initial={{opacity:0,scale:0, transition: { duration: 0.1, ease: "backInOut"}}} 
                                                                animate={{opacity:100,scale:1, transition: { duration: 0.4 , ease: "backInOut"}}} 
                                                                exit={{opacity:0,scale:5, transition: { duration: 0.4, ease: "easeInOut" }}}>
                                                            <div className='row'>
                                                                <div className='glitchy-text col-8'>Development</div>
                                                                <FontAwesomeIcon icon={faTerminal} style={{ color: '#ffffff', marginTop: '3px' }} className='col'/>
                                                            </div>
                                                        </motion.div>:
                        (location.pathname === '/android')?<motion.div className='p-2 fw-light border-5 selected-navbar-indicator' style={{ backgroundColor, color: textColor, transition: 'background-color 0.5s easeInOut' , zIndex:10000}}
                                                                initial={{opacity:0,scale:0, transition: { duration: 0.1, ease: "backInOut"}}} 
                                                                animate={{opacity:100,scale:1, transition: { duration: 0.4 , ease: "backInOut"}}} 
                                                                exit={{opacity:0,scale:5, transition: { duration: 0.4, ease: "easeInOut" }}}>
                                                            <div className='row'>
                                                                <div className='col-6'>Android</div>
                                                                <FontAwesomeIcon icon={faMobileAndroid} style={{ color: '#BBBBBB', marginTop: '3px' }} className='col-2'/>
                                                            </div>
                                                            </motion.div>:
                        (location.pathname === '/games')?<motion.div className='p-2 fw-light border-5 selected-navbar-indicator' style={{ backgroundColor, color: textColor, transition: 'background-color 0.5s easeInOut' , zIndex:10000}}
                                                                initial={{opacity:0,scale:0, transition: { duration: 0.1, ease: "backInOut"}}} 
                                                                animate={{opacity:100,scale:1, transition: { duration: 0.4 , ease: "backInOut"}}} 
                                                                exit={{opacity:0,scale:5, transition: { duration: 0.4, ease: "easeInOut" }}}>
                                                            <div className='row'>
                                                                <div className='col-6'>Games</div>
                                                                <FontAwesomeIcon icon={faGamepad} style={{ color: '#BBBBBB', marginTop: '3px' }} className='col-2'/>
                                                            </div>
                                                            </motion.div>:
                        (location.pathname === '/contact')&&<motion.div className='p-2 fw-light border-5 selected-navbar-indicator' style={{ backgroundColor, color: textColor, transition: 'background-color 0.5s easeInOut' , zIndex:10000}}
                                                                initial={{opacity:0,scale:0, transition: { duration: 0.1, ease: "backInOut"}}} 
                                                                animate={{opacity:100,scale:1, transition: { duration: 0.4 , ease: "backInOut"}}} 
                                                                exit={{opacity:0,scale:5, transition: { duration: 0.4, ease: "easeInOut" }}}>
                                                            <div className='row'>
                                                                <div className='col font-smaller overflow-visible flex-wrap'>Contact</div>
                                                                <FontAwesomeIcon icon={faContactCard} style={{ color: '#000000', marginTop: '3px' }} className='col'/>
                                                            </div>
                                                            </motion.div>
                    }</AnimatePresence>
                    
                    <br />
                    <Button
                        id='collapseButton'
                        ref={inputRef}
                        color='secondary'
                        variant="outlined"
                        className="navbar-toggler"
                        type="button"
                        aria-controls="navbarContent"
                        aria-expanded={!isCollapsed}
                        aria-label="Toggle navigation"
                        onClick={toggleNavbar}
                    >
                        <span className="navbar-toggler-icon"></span>
                    </Button>
                    {!isCollapsed&&
                    <motion.div className={`navbar-collapse show`} id="navbarContent"
                        initial={{opacity:0, transition: { duration: 0.1, ease: "easeInOut"}}} 
                        animate={{opacity:100,x:'0%', transition: { duration: 0.8 , ease: "easeInOut"}}} 
                        exit={{opacity:0, x:'50vw', transition: { duration: 0.3, ease: "easeInOut" }}}>
                        <ul className="nav navbar-nav mx-auto">
                            <li key="home" className={`nav-item ${curLocation === '/' ? 'chosen' : ''}`}>
                                <Link to="/" className={`nav-link ms-3 me-3 fw-normal text-decoration ${curLocation === '/' ? 'chosen' : ''}`} onClick={collapseHandle} style={{ color: textColor, transition: 'text-color 0.5s ease' }}>Home</Link>
                            </li>
                            <li key="bio" className={`nav-item ${curLocation === '/bio' ? 'chosen' : ''}`}>
                                <Link to="/bio" className="nav-link ms-3 me-3 fw-normal text-decoration" onClick={collapseHandle} style={{ color: textColor, transition: 'text-color 0.5s ease' }}>Biography</Link>
                            </li>
                            <li key="web" className={`nav-item ${curLocation === '/web' ? 'chosen-inv' : ''}`}>
                                <Link to="/web" className="nav-link ms-3 me-3 fw-normal text-decoration" onClick={collapseHandle} style={{ color: textColor, transition: 'text-color 0.5s ease' }}>Web Development</Link>
                            </li>
                            <li key="android" className={`nav-item ${curLocation === '/android' ? 'chosen' : ''}`}>
                                <Link to="/android" className="nav-link ms-3 me-3 fw-normal text-decoration" onClick={collapseHandle} style={{ color: textColor, transition: 'text-color 0.5s ease' }}>Android Development</Link>
                            </li>
                            <li key="games" className={`nav-item ${curLocation === '/games' ? 'chosen' : ''}`}>
                                <Link to="/games" className="nav-link ms-3 me-3 fw-normal text-decoration" onClick={collapseHandle} style={{ color: textColor, transition: 'text-color 0.5s ease' }}>Games</Link>
                            </li>
                            <li key="contact" className={`nav-item ${curLocation === '/contact' ? 'chosen' : ''}`}>
                                <Link to="/contact" className="nav-link ms-3 me-3 fw-normal text-decoration" onClick={collapseHandle} style={{ color: textColor, transition: 'text-color 0.5s ease' }}>Contact</Link>
                            </li>
                        </ul>
                    </motion.div>
                    }
                </div>
            </nav>
        </motion.div>
    );
}

export default Navbar;
