import React, { Component } from 'react';
import "./Contact.css";
import { Link } from "react-router-dom"
import { useSpring, animated } from '@react-spring/web'
import { useState, useEffect } from 'react';
import {motion} from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile } from '@fortawesome/free-regular-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import emailjs from "emailjs-com";

function Contact(){

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [responseMessage, setResponseMessage] = useState("");

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

const handleSubmit = async (e) => {
  e.preventDefault();

  if (isSubmitting) return; // Prevent duplicate submissions

  setIsSubmitting(true);
  try {
    const response = await emailjs.send(
      "service_m8jualr",
      "template_rirxmgc",
      {
        from_name: formData.name,
        from_email: formData.email,
        message: formData.message,
      },
      "haeRNQRr32rzPKzHO"
    );

    if (response.status === 200) {
      setResponseMessage("Your message has been sent successfully!");
    } else {
      setResponseMessage("Failed to send your message. Please try again.");
    }
  } catch (error) {
    console.error("Error sending email:", error);
    setResponseMessage("An error occurred. Please try again later.");
  } finally {
    setIsSubmitting(false); // Re-enable the button
  }
};

  const [propsSlideRightOp] = useSpring(
    () => ({
      from: { opacity: 0, y: '-100%' },
      to: { opacity: 1 , y: '0%' },
      delay: 500
    }),
    []
  )

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // After the component is mounted, set isVisible to true
    setIsVisible(true);
    emailjs.init({
      publicKey: "haeRNQRr32rzPKzHO",
    });

    // Cleanup function for when the component is about to unmount
    return () => {
      setIsVisible(false);
    };
  }, []);
  
    return (
    <div> 
    <script type="text/javascript" src="https://cdn.jsdelivr.net/npm/@emailjs/browser@4/dist/email.min.js"></script>
     
      <motion.div exit={{opacity:0, transition: { duration:0.2, ease: "easeInOut" }}} className={`container-fluid my-body ${isVisible ? 'visible' : ''}`}>

        <motion.div exit={{opacity:0, transition: { duration:0.2, ease: "easeInOut" }}}>
            <animated.div style={propsSlideRightOp}>
              <div className='container-fluid top-bar'>
          
                <div className='row align-items-center '>
                  <div className='h1 fw-light text-decoration'>Contact me</div>
                </div>
              </div>
            </animated.div>
        </motion.div>

        <br/>

        <motion.div key="contact-body" initial={{opacity:0 , transition: { duration: 0.1}}} animate={{opacity:100, transition: { duration: 0.7, delay:0.5 , ease: "easeInOut"}}} exit={{opacity:0, x:'-100%', transition: { duration: 0.2, ease: "easeInOut" }}} className="my-div">
          <div className='details-div'>
            <div className='h3 fw text-decoration'>Konstantinos Antzoulidis</div>
          
            <p className="h5 fw-light text-lg-start"><FontAwesomeIcon icon={faLocationDot} size="lg" /> Athens, Greece</p>
            <p className="h5 fw-light text-lg-start"><FontAwesomeIcon icon={faLocationDot} size="lg" /> Tunbridge Wells, United Kingdom</p>
            <p className="h5 fw-light text-lg-start"><FontAwesomeIcon icon={faEnvelope} size="lg" /> kostas.antzou@gmail.com</p>
            <p className="h5 fw-light text-lg-start"><FontAwesomeIcon icon={faLinkedin} size="lg" /> linkedin.com/in/k.antzoulidis</p>
          </div>
        </motion.div>

        <motion.div key="body" initial={{opacity:0, transition: { duration: 0.1}}} animate={{opacity:100, transition: { duration: 0.3, delay:0.8 , ease: "easeInOut"}}} exit={{opacity:0, x:'-100%', transition: { duration: 0.2, ease: "easeInOut" }}} className="my-div">
  
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3 mt-5">
            <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Your Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Your Email
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label">
                Message
              </label>
              <textarea
                className="form-control"
                id="message"
                rows="4"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <div className="text-center">
            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
              {isSubmitting ? "Sending..." : "Submit"}
            </button>

            </div>
          </form>
              {responseMessage && <p className="mt-3 text-center">{responseMessage}</p>}
            </div>
          </div>
        </div>



        </motion.div>

      </motion.div>
      


    </div>

    );
    
  }
export default Contact;